@import "../../assets/variables";
.loader {
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}