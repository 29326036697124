@import "variables";
@import "mixins";

.ReactTable {
  height: fit-content;
  margin-top: 31px;
  width: 1121px;
  border: initial !important;

  @include mq($q12) {
    overflow-x: scroll;
  }

  .rt-thead {
    .rt-tr {
      .rt-th {
        padding: 0 16px;

        &:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .-sort-asc {
    .rt-resizable-header-content {
      color: #fe5900 !important;
    }

    .rt-resizer {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .rt-thead.-header {
    box-shadow: unset !important;

    .rt-tr {
      background: #fcfcfd;
      border-bottom: 1px solid #f6f6f7;
      width: 1268px;
      display: box;

      .rt-th {
        align-items: center;
        display: flex;
        white-space: pre-line !important;
        word-wrap: break-word;
      }

      .rt-th,
      .rt-td {
        background: #f2f4f5;
        color: #415962;
        padding: 12px 16px !important;
        border-right: initial !important;
        justify-content: flex-start;
        width: 262px !important;

        .rt-resizable-header-content {
          font-family: $font-default-family;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 24px;
          color: #757679;
          padding-right: 3rem;
          padding-left: 1rem;
          text-align: left;

          .rtl-class & {
            text-align: right;
            padding-right: 1rem;
            padding-left: 3rem;
          }
        }

        &.rt-resizable-header {
          @media screen and (max-width: 568px) {
            &.rt-resizable-header {
              width: 250px;
            }
          }

          &:first-child {
            .rt-resizable-header-content {
              display: flex;
              text-align: center;
              margin: 0;
              justify-content: center;

              .checkbox-wrap {
                position: absolute;
                top: 0;
              }
            }
          }
        }

        &.-cursor-pointer {
          &::after {
            content: "";
            position: absolute;
            width: 24px;
            height: 24px;

            .ltr-class & {
              right: 16px;
            }

            .rtl-class & {
              left: 16px;
            }
          }
        }

        &.-sort-desc {
          box-shadow: unset !important;
        }

        &.-sort-asc {
          box-shadow: unset !important;

          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.-striped .rt-tr.-odd {
    background: unset !important;
  }

  .rt-table {
    overflow: hidden;
  }

  .rt-tbody {
    overflow: hidden;
    border-left: 5px solid #fe5900;

    .rt-tr-group {
      border-bottom: solid 1px #f6f6f7 !important;
      width: 1239px;

      &:hover {
        background-color: #fcfcfd !important;
        cursor: pointer;
      }
    }

    .rt-tr:not(.-padRow):hover {
      background-color: #fcfcfd !important;
    }

    .rt-rd {
    }
  }

  .rt-rd {
    padding: 12px 5px !important;
  }

  .rt-td {
    padding: 12px 16px !important;
    text-align: left !important;
    border-right: initial !important;
    text-overflow: initial !important;
    overflow: initial !important;

    .rtl-class & {
      text-align: right !important;
    }
  }

  .-pagination {
    display: none;
  }

  .rt-table {
    @include mq($q12) {
      //overflow: unset !important;
      //overflow-x: scroll  !important;
    }
  }

  .rt-tbody {
    @include mq($q12) {
      overflow: unset !important;
      user-select: unset !important;
    }

    .rt-tr-group {
      .rt-td {
        @include mq($q12) {
          width: 220px !important;
        }
      }
    }
  }

  .rt-thead {
    @include mq($q12) {
      //user-select: unset !important;
    }

    .rt-tr {
      .rt-th {
        @include mq($q12) {
          width: 220px !important;
        }
      }
    }
  }
}
