@import "../../assets/mixins";
@import "../../assets/variables";

.modalContainer {
  background-color: #fff;
  padding: 32px;
  border-radius: 8px;
  width: 100%;
}

.modalWrapperContainer {
  width: 100%;
  max-width: 800px;
  @media (max-width: 840px) {
    height: 80vh;
    overflow: auto;
  }
}

.wrapper {
  display: flex;
  gap: 32px;

  @media (max-width: 840px) {
    flex-direction: column;
  }
}

.priceWrapper {
  display: inline-flex !important;
  align-items: baseline;
  gap: 5px;

  .rtl-class & {
    flex-direction: row-reverse;
  }

  .price {
    font-size: 21px;
    margin-top: -3px;
    color: #fe5900 !important;
  }
}

.priceBefore {
  text-decoration: line-through;
  color: #415962;
  font-weight: 500;
}

// .price {
//   color: #fe5900 !important;
// }

.imgContainer {
  position: relative;
  width: 352px;
  height: 352px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $black-5;
}

.closeSvg {
  position: absolute;
  right: -16px;
  background: #fff7f2;
  border-radius: 50%;
  top: -16px;
  padding: 4px;
  z-index: 1000;

  @media (max-width: 840px) {
    top: 16px;
    right: 16px;
  }
}

.couponInfo {
  width: 100%;
  max-width: 352px;
}

.companyContainer {
  width: 100%;
  margin: 16px 0 24px 0;

  @include mq($q428) {
    flex-direction: column;
  }
}

.companyName {
  display: flex;
  margin-top: 32px;
  margin-bottom: 8px;
}

.heading {
  font-size: 12px;

  @include mq($q428) {
    font-size: 16px;
  }
}

.heading,
.price {
  text-transform: uppercase;
  font-weight: bold;
  color: #415962;
}

.price {
  color: #fe5900 !important;
}

.description {
  font-size: 18px;

  @include mq($q428) {
    font-size: 16px;
  }
}

.couponPageSlider {
  height: 352px;
  :global {
    .swiper-button-next,
    .swiper-button-prev {
      transform: rotate(90deg);
      bottom: 0;
      @media (max-width: 1279px) {
        display: none;
      }
    }
    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      right: 45px;
      top: unset;
      z-index: 20;

      @include mq($q16) {
        right: 33px;
      }
      &:after {
        font-size: 20px;
        color: $primary;
      }
    }
    .swiper-pagination {
      display: none;

      @media (max-width: 1279px) {
        display: block;
        bottom: -16px;
      }
    }
    .swiper-pagination-bullet-active {
      background: $primary;
    }
    .swiper-slide {
      overflow: hidden;
      border: 1px solid $black-5;
      border-radius: 6px;
      padding: 56px;
      > div > div > div {
        background-size: contain !important;
        height: 240px !important;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      &:last-child {
        @media (max-width: 1279px) {
          margin-right: unset;
        }
      }
    }
  }

  .singleCoupon {
    @media (max-width: 500px) {
      width: 90% !important;
    }
  }
}

.thumbsContainer {
  width: 352px;
  display: flex;
  margin-top: 16px;
  position: relative;
  :global {
    .swiper-slide {
      width: 76px !important;
      height: 76px !important;
      border: 1px solid $black-5;
      border-radius: 6px;
      cursor: pointer;
    }
    .swiper-button-disabled {
      display: none;
    }
    .swiper-button-prev {
      top: 44px;
      left: -23px;
      &::after {
        width: 16px;
        height: 8px;
        transform: rotate(180deg);
        content: url("../../assets//svg/swiper-icon.svg") !important;
      }
    }
    .swiper-button-next {
      top: 33px;
      right: -23px;
      &::after {
        width: 13px;
        height: 8px;
        content: url("../../assets//svg/swiper-icon.svg") !important;
      }
    }
  }
}

.mainImg {
  width: 240px;
  height: 240px;
  object-fit: contain;
}

.thumbsSlider {
  margin-right: unset !important;
  margin-left: unset !important;
  height: 76px;
  width: 352px;
  position: relative;
}

.thumbsImg {
  height: 56px;
  width: 56px;
  margin: 10px;
  object-fit: contain;
}

.activeSlide {
  border: 1px solid $primary !important;
}

.rtl {
  text-align: right;
  direction: rtl;

  .companyContainer {
    text-align: right;
    direction: rtl;
  }

  .thumbsContainer {
    :global {
      .swiper-button-prev {
        top: 33px;
        right: -23px;
        &::after {
          transform: rotate(0);
        }
      }
      .swiper-button-next {
        top: 44px;
        left: -23px;
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .closeSvg {
    left: -16px;
    right: unset;

    @media (max-width: 840px) {
      left: 16px;
    }
  }

  @include mq($q10) {
    padding-left: 0;
  }
  .thumbsSlider {
    margin-left: 0;
    margin-right: 16px;
  }

  .thumbsContainer {
    &:before {
      right: unset;
      left: 0;
    }
  }

  .couponPageSlider {
    margin-left: unset;
    :global {
      .swiper-button-next,
      .swiper-button-prev {
        transform: rotate(270deg);
      }
      .swiper-button-next {
        right: unset;
        left: 45px;

        @include mq($q16) {
          right: unset;
          left: 33px;
        }
        &:after {
          font-size: 20px;
          color: $primary;
        }
      }
      .swiper-slide {
        &:last-child {
          @media (max-width: 1279px) {
            margin-right: 0 !important;
            margin-left: 40px !important;
          }
        }
      }
    }
  }

  .swiperContainer {
    @media (max-width: 1279px) {
      margin-right: 0;
      margin-left: -40px;
    }
  }
}
