@import "../../assets/variables";

.input {
  background-color: #fff !important;
  outline: none !important;
  border: 1px solid $dark-5 !important;
  border-radius: 5px !important;
  padding: 16px 24px 16px 70px !important;
  color: $dark-50 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.2px !important;
  font-family: "Heebo" !important;
  box-sizing: border-box !important;
  height: 56px !important;
  width: 100% !important;
  direction: ltr !important;
}

.label {
  display: block;
}

.container {
  :global {
    .react-tel-input {
      .flag-dropdown {
        background-color: #fff !important;
        border: none;
        height: 53px;
        top: 2px;
        left: 2px;
        padding-left: 24px;
      }
    }
  }
}

.sendNumber {
  margin-bottom: 48px;

  @media (max-width: 1440px) {
    margin-bottom: 40px;
  }

  @media (max-width: 768px) {
    margin-bottom: 56px;
  }
}

.authError {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  color: red !important;
}

.sentSms {
  font-family: "Heebo" !important;
}

.sentMail {
  width: fit-content;
}

.smsBlock {
  width: 300px;
  margin: 0 auto;
  display: flex;
}

.verification {
  margin: 0 auto;
  width: max-content;
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: "Heebo";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    text-transform: unset;
  }
}

.inputCode {
  margin-top: 56px !important;

  @media (max-width: 1440px) {
    margin-top: 46px !important;
  }

  @media (max-width: 768px) {
    margin-top: 56px !important;
  }

  @media (max-width: 376px) {
    margin-top: 64px !important;
  }
}

.inputFrom {
  margin: 0;
}

.timerBlock {
  margin-top: 24px !important;
  margin-bottom: 24px !important;

  @media (max-width: 1440px) {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }

  @media (max-width: 768px) {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  @media (max-width: 376px) {
    margin-top: 28px !important;
    margin-bottom: 0px !important;
  }
}

.viaBlock {
  margin-bottom: 32px;

  @media (max-width: 1440px) {
    margin-bottom: 27px;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
}

.viaContainer {
  @media (max-width: 768px) {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.buttonInEmail {
  margin-top: 32px;
  margin-bottom: 24px;

  @media (max-width: 1440px) {
    margin-top: 27px;
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 8px;
  }

  @media (max-width: 376px) {
    margin-bottom: 0px;
  }
}

.sentText {
  font-weight: 700;
}

.getSms {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.activeText {
  color: #fe5900;
}

.textMobileSingIn {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
}

.mobileActiveBlock {
  padding: 16px 0 !important;

  @media (max-width: 768px) {
    margin-top: 8px !important;
  }
}

.containerButton {
  @media (max-width: 375px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.buttonBack {
  border: 1px solid #fe5900 !important;
}
