.update-modal-wrapper {
  display: none;

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000080;
  }
}

.update-modal {
  position: relative;
  z-index: 100000000;
  padding: 20px;
  max-width: 300px;
  min-width: 240px;
  background: #ffffff;
  // box-shadow: 0px 10px 20px rgb(9 10 49 / 30%);
  border-radius: 10px;
  font-size: 13px;
  background: #fef7f3;
  overflow: hidden;

  @media (min-width: 768px) {
    position: fixed;
    left: 25px;
    bottom: 25px;
    box-shadow: 0px 10px 20px 17px rgba(9, 10, 49, 0.1);
    /* background: white; */
    border: 1px solid #fe590040;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 60%;
    background: #ffffff;
    border-radius: 10% 100%;
    z-index: -1;
    transform: translateX(10%) translateY(-50%);
    // background-image: radial-gradient(circle at top right, transparent 0%, transparent 70%, #ffffff 80%);
  }

  .content {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-size: 14px;

    h2 {
      font-size: 18px !important;
      font-weight: 400 !important;
      line-height: 24px;
    }

    .frame {
      width: 200px;
      margin: 30px 0;
    }

    .ios {
      width: 120px !important;
    }
  }

  @media (max-width: 767px) {
    // width: 80%;
    // max-width: 80%;
    // top: 20%;
    // left: 10%;
    // bottom: auto;
    // font-size: 15px;
  }

  .link {
    background: var(--primary);
    color: #ffffff;
    padding: 10px 10px;
    width: 100%;
    border-radius: 5px;
    display: block;
    text-align: center;
    font-size: 15px;
    // text-transform: uppercase;
    margin-top: 35px;
  }

  .close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    // width: 26px;
    padding: 6px;
    cursor: pointer;
    background: transparent;
    border: none;
    // box-shadow: 0px 10px 20px rgba(9, 10, 49, 0.1);

    img {
      width: 28px;
    }

    // box-shadow: 0px 10px 20px rgba(9, 10, 49, 0.1);

    // img {
    //   width: 23px;
    // }

    &:hover {
      background: #f5f5f5;
      border-radius: 50%;
    }
  }
}
