.notificationBreak {
  @media (min-width: 500px) {
    display: none;
  }
}

.titleNotif {
  cursor: pointer;
}

.notification-info {
  display: flex;
  @media (max-width: 500px) {
    display: none !important;
  }
}

.expanded-info {
  @media (max-width: 500px) {
    display: flex !important;
  }
}

.notification-info-text {
  br {
    @media (min-width: 500px) {
      display: none !important;
    }
  }
}
