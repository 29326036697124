.sub-menu {
  background: #fafafd;

  @media (max-width: 1279px) {
    display: none;
  }

  &__list {
    display: flex;
    gap: 40px;
  }

  &__item {
    height: 35px;
    display: flex;
    align-items: center;
    font-weight: 500;

    a {
      display: flex;
      align-items: center;
      color: var(--dark);
      gap: 6px;
      font-size: 15px;
    }

    img {
      margin-top: 2px;
    }
  }
}
