.chipper-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media (min-width: 1400px) {
    padding: 0 80px;
    max-width: 1400px;
  }

  @media (min-width: 1500px) {
    padding: 0 80px;
    max-width: 1500px;
  }

  @media (min-width: 1600px) {
    padding: 0 80px;
    max-width: 1600px;
  }

  @media (max-width: 1200px) {
    padding: 0 40px;
  }

  @media (max-width: 450px) {
    padding: 0 16px;
  }
}

.chipper-container-reset {
  margin-left: -60px;
  margin-right: -60px;

  @media (min-width: 1400px) {
    margin-left: -80px;
    margin-right: -80px;
  }

  @media (min-width: 1600px) {
    margin-left: -80px;
    margin-right: -80px;
  }

  @media (max-width: 1200px) {
    margin-left: -40px;
    margin-right: -40px;
  }

  @media (max-width: 420px) {
    margin-left: -16px;
    margin-right: -16px;
  }
}

// #userwayAccessibilityIcon {
//   transform: none !important;
//   right: 0px;
//   top: unset !important;
//   bottom: 40px !important;
// }

// @media (max-width: 500px) {
//   #userwayAccessibilityIcon {
//     right: 2px !important;
//     top: 95px !important;
//     bottom: unset !important;
//   }

//   html:lang(he) {
//     #userwayAccessibilityIcon {
//       left: 2px !important;
//       top: 95px !important;
//     }
//   }
// }
