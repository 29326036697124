@import "../../assets/variables";

.inputContainer {
  width: 100%;
  position: relative;
}

.input {
  outline: none;
  border: 1px solid $dark-5;
  border-radius: 5px;
  padding: 16px 24px;
  color: $dark;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  font-family: 'Heebo';
  box-sizing: border-box;
  height: 56px;
  width: 100%;
}

.inputError {
  border: 1px solid #FB4E4E;
}