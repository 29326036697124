.alerts {
  z-index: 100000000;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  .rtl-class & {
    right: unset;
    left: 0;
  }

  img {
    padding: 1rem;

    &:last-child {
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }

  .alert-block {
    width: fit-content;
    margin: 1rem 0;

    p {
      color: white !important;
    }
  }

  .success-alert {
    background: #2AC769;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }

  .warning-alert {
    background: #F6A609;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }

  .info-alert {
    background: #3795EC;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }

  .danger-alert {
    background: #FB4E4E;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }
}

.alerts_block {
  animation: fadeIn 1s linear forwards;
}

.alerts_none {
  animation: fadeOut 1s linear forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    max-height: 100px;
  }

  50% {
    max-height: 50px;
  }

  100% {
    max-height: 0;
  }
}

@media all and (max-width: 457px) {
  .alerts {
    .alert-block {
      p {
        font-size: 10px !important;
      }
    }
  }
}