.coupons {
  display: flex;
  flex-flow: row wrap;
  position: relative;

  .item {
    margin: 4px;
    flex: 0 1 calc(20% - 8px); /* <-- adjusting for margin */
  }
}
