.closeSvg {
  position: absolute;
  right: -16px;
  background: #FFF7F2;
  border-radius: 50%;
  top: -16px;
  padding: 4px;
  z-index: 1000;
}

.closeSvgRtl {
  right: unset;
  left: -16px
}

.modalRtl {
  direction: rtl;
}