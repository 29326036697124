@import "mixins";
@import "variables";

.footer {
  background: $dark;
  text-align: initial;

  .Download-App {
    &__Row {
      display: flex;
      position: relative;

      .rtl-class & {
        left: 17px;
      }

      @include mq($q14) {
        justify-content: center;
      }

      @include mq($q6) {
        // flex-direction: column;
        align-items: center;
      }

      &-Item {
        background: #1A3843;
        border-radius: 5px;
        padding: 8px 8px 8px 16px;
        margin-right: $spacer-3;
        text-align: initial;
        border: 1px solid #415962;
        box-shadow: 0 4px 16px rgba(254, 89, 0, 0.08);

        @include mq($q6) {
          width: 164px;
        }

        @include mq($q2) {
          padding: 8px 8px 8px 6px;
        }

        a {
          display: flex;
          justify-content: space-around;
          //@include mq($q6) {
          //  display: flex;
          //  justify-content: space-around;
          //}
        }

        &:last-child {
          img {
            filter: unset;
          }
        }

        .Qr-Foot {
          margin-left: 16px;
          margin-right: 0;
          width: 40px;
          height: 40px;
          filter: unset;

          .rtl-class & {
            margin-left: unset;
            margin-right: 16px;
          }

          @include mq($q8) {
            display: none;
          }
        }

        .Item-Content {
          display: flex;
          align-items: center;

          &:hover {
            .Title {
              color: #415962;
            }

            .Desc {
              color: #01222E;
            }
          }

          .Title {
            font-size: 12px;
            line-height: 16px;
            color: #D9DEE0;
          }

          .Desc {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: white;
          }
        }

        img {
          filter: brightness(0) invert(1);
          margin-right: $spacer-3;

          .rtl-class & {
            margin-right: unset;
            margin-left: $spacer-3;
          }
        }

        .google-img {
          img {
            filter: unset;
          }
        }

        //.rtl-class & {
        //  margin-right: unset;
        //  margin-left: $spacer-3;
        //}
        &:hover {
          cursor: pointer;

          img {
            filter: unset;
          }

          .p-small-block {
            color: #415962 !important;
          }

          .p-large-block {
            color: #01222E !important;
          }

          background: #FFF;
        }
      }
    }
  }

  .footer-block {
    padding: 80px 148px;

    @media (max-width: ($grid-breakpoint-lg)-1) {
      padding: 0;
    }

    @include mq($q14) {
      padding: 80px 45px;
    }

    @include mq($q6) {
      padding: 0;
    }

    .menu p,
    .menu h3 {
      color: white !important;
    }

    .item {
      margin-bottom: $spacer-2;

      p {
        color: $dark-25 !important;

        &:hover {
          color: white !important;
        }
      }
    }

    .block-item {

      @media (max-width: ($grid-breakpoint-lg)-1) {
        display: none;
        text-align: center;
        margin-bottom: 72px;
        padding: $spacer-6 0;
      }

      &.item_links {
        display: unset !important;

        @media (max-width: ($grid-breakpoint-lg)-1) {
          width: 100%;
        }
      }

      .soc {
        background: #1A3843;
        border-radius: 5px;
        padding: $spacer-2;
        margin-right: $spacer-3;

        img {
          filter: brightness(0) invert(1);
        }

        .rtl-class & {
          margin-right: unset;
          margin-left: $spacer-3;
        }

        @media (max-width: ($grid-breakpoint-lg)-1) {
          &:last-child {
            margin-right: 0;
          }
        }

        &:hover {
          cursor: pointer;

          img {
            filter: unset;
          }

          background: #fff;
        }
      }

      .d-flex {
        @media (max-width: ($grid-breakpoint-lg)-1) {
          justify-content: center;
        }
      }
    }
  }
}

.footer-2 {
  background: #00131B;
  margin-top: -1px;

  .menu p {
    color: $dark-25 !important;
    font-family: $font-button-family !important;

    &:hover {
      color: white !important;
    }
  }

  .terms {
    margin-left: $spacer-5;

    .rtl-class & {
      margin-right: $spacer-5;
    }
  }

  .accessibility_statement {
    margin-right: $spacer-5;
  }

  @media (max-width: ($grid-breakpoint-lg)-1) {
    display: none;
  }
}
