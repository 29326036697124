@import "../../assets/mixins";
@import "../../assets/variables";

.heart {
  cursor: pointer;
  bottom: -22px;
  width: 48px;
  height: 48px;
  right: 24px;
  background-color: white;
  border-radius: 30px;
  position: absolute;
  padding: 0.5rem;
  &.couponPageWishlist {
    bottom: 24px;
    &::after {
      height: 48px;
      border-bottom-left-radius: 110px;
      border-bottom-right-radius: 110px;
      border-bottom: 1px solid #f2f4f5;
    }
  }

  &::after {
    content: "";
    width: 48px;
    height: 27px;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border: 1px solid #f2f4f5;
    border-bottom: 0;
    top: 0;
    left: 0;
    position: absolute;
  }
  &:hover {
    .sliderTooltip {
      display: block;
      background: #01222e;
      border-radius: 8px;
      padding: 0.5rem 1rem;
      color: white !important;
      font-weight: normal;
      position: absolute;
      right: 55px;
      z-index: 1;
      min-width: 200px;
      height: fit-content;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 272px;
      transition: opacity 0.3s ease-out;
      opacity: 0.9;
      // z-index: 9999;

      @media (max-width: ($grid-breakpoint-sm)) {
        width: 100%;
      }

      p {
        text-transform: initial;
        color: white !important;
      }

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 16px;
        border-left: 9px solid #01222e;
        border-bottom: 8px solid transparent;
        border-top: 8px solid transparent;
        top: 0;
        bottom: 0;
        right: -8px;
        margin: auto;
      }
    }
  }
}

.sliderTooltip {
  display: none;
}

.rtl {
  right: unset;
  left: 24px;
  &:hover {
    .sliderTooltip {
      right: unset;
      left: 100%;
      margin-left: 10px;
      width: auto;
      &::before {
        right: unset;
        left: -8px;
        border-right: 9px solid #01222e;
        border-left: unset;
      }
    }
  }
}
