@import '../../assets/variables';

.modalContent {
  @media (max-width: 600px) {
    width: 100%;
    margin: 0 16px;
  }
}

.modalWrapper {
  width: 544px;
  background-color: $primary;
  padding: 40px 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.text {
  margin-top: 31px;
  margin-bottom: 24px;
}

.qr {
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
}

.useCouponNumber {
  margin-top: 32px;
  margin-bottom: 16px;
}

.couponNumberContainer {
  width: 288px;
  border-radius: 5px;
  padding: 16px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.couponNumberLabel {
  color: $dark-75;
}

.couponNumber {
  color: $dark;
  font-weight: 700;
}

.closeSvg {
  position: absolute;
  right: -16px;
  background: #FFF7F2;
  border-radius: 50%;
  top: -16px;
  padding: 4px;
  z-index: 1000;
}