@import '../../assets/variables';

.socialLink {
  margin-top: 32px !important;

  gap: 24px;

  @media (max-width: 1440px) {
    margin-top: 27px !important;
  }

  @media (max-width: 768px) {
    margin-top: 32px !important;
  }

  @media (max-width: 376px) {
    margin-top: 24px !important;
  }

  a {
    margin: 0px !important;
  }
}

.modalLogin {
  @media (max-width: 1440px) {
    padding: 46px 25px !important;
  }

  @media (max-width: 768px) {
    padding: 24px !important;
  }
}

.isMobileApple {
  flex-basis: unset !important;
  width: calc(50% - 12px) !important;
}