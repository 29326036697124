.coupon-expiration {
  background: var(--secondary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  img {
    width: 16px;
    margin-top: -1px;
  }

  &--special {
    padding: 7px 0;
    font-size: 16px;
  }
}
