@import "../assets/mixins";
@import "../assets/variables";

.swiperContainer {
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  &::after {
    display: table;
    content: '';
    position: absolute;
    height: 360px;
    width: 14%;
    background: #f3ffff;
    top: 0;
    z-index: 10;
    background: linear-gradient(90deg, #FFF7F2 0%, rgba(255, 247, 242, 0.84) 29.62%, rgba(255, 247, 242, 0) 100%);

    @include mq($q10) {
      display: none;
    }
  }

  &::before {
    display: table;
    content: '';
    position: absolute;
    height: 360px;
    width: 14%;
    background: #f3ffff;
    top: 0;
    right: 0;
    z-index: 10;
    background: linear-gradient(270deg, #FFF7F2 0%, rgba(255, 247, 242, 0.84) 29.62%, rgba(255, 247, 242, 0) 100%);

    @include mq($q10) {
      display: none;
    }
  }
}

.rtl {
  &::after {
    left: 0;
  }

  &::before {
    left: unset;
  }

  :global {
    .swiperDeals {
      @include mq($q10) {
        padding-left: 0 !important;
        padding-right: 20px !important;
      }
    }
  }

  .centered {
    @include mqm($q14) {
      :global {
        .swiper-wrapper {
          justify-content: center;
        }

        .swiper-slide:last-child {
          margin-left: 0 !important;
        }
      }
    }
  }
}


.centered {
  @include mqm($q14) {
    :global {
      .swiper-wrapper {
        justify-content: center;
      }

      .swiper-slide:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

:global {
  .swiper {
    position: unset !important;
  }

  .swiperDeals {
    padding-top: 8px !important;

    //TODO return to crop slides on the both sides(right-left)
    //margin-left: -100px !important;
    //margin-right: -100px !important;
    @include mq($q10) {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .swiper-pagination {
      bottom: -34px !important;
    }

    .swiper-wrapper {
      height: auto;
    }

    .swiper-slide {
      width: auto !important;
    }

    .swiper-pagination-bullet-active {
      background: #FE5900;
    }

    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      background: #fff;
      border-radius: 43px;
      border: 2px solid #FE5900;
      box-sizing: border-box;
      width: 48px;
      height: 48px;
      box-shadow: 0px 4px 24px rgba(254, 89, 0, 0.08);
      z-index: 11;

      @include mq($q10) {
        display: none;
      }
    }

    .swiper-button-next {
      right: 24px;

      &:hover {
        background: #FE5900;
      }
    }

    .swiper-button-prev {
      left: 24px;

      &:hover {
        background: #FE5900;
      }
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 12px;
      color: #FE5900;
    }

    .swiper-button-next:hover::after,
    .swiper-button-prev:hover::after {
      color: white;
    }
  }

}

.footer {
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 56px 0;
  }
}

.footerWrapper {
  padding-top: 80px;

  @media (max-width: 768px) {
    padding-top: 56px;
  }
}