.leftPosition {
  position: relative;
  left: -10px;

  img {
    margin-right: 10px;

    .rtl-class & {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .rtl-class & {
    right: -10px;
    left: unset;
  }
}

.categories {
  position: relative;

  .ScrollbarsCustom-Scroller {
    // @media screen and (max-width: 480px) {
    //   padding-right: 21px !important;
    // }
  }

  .ScrollbarsCustom-Track {
    opacity: 0.4;
  }

  &:hover {
    .ScrollbarsCustom-Track {
      opacity: 1;
    }
  }

  .checker {
    position: absolute;
    right: 2rem;
    top: 1rem;

    &:hover {
      cursor: pointer;
    }

    &.unchecked {
      &::before {
        content: "";
        background: url("./images/check.svg") no-repeat;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
      }
    }

    &.checked {
      p {
        color: $primary !important;
      }

      &::before {
        content: "";
        background: url("./images/check-1.svg") no-repeat;
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
      }
    }
  }

  .categories_block {
    .responsive_cat {
      @include mq($q3) {
        flex: 0 0 50%;
        max-width: 48%;
      }

      @include mq($q2) {
        flex: 0 0 50%;
        max-width: 47%;
      }

      @include mq($q1) {
        flex: 0 0 50%;
        max-width: 46%;
      }

      .category {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border: 1px solid #D9DEE0;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 0.8rem;
        width: 225px;
        text-align: center;
        margin-bottom: $spacer-5;

        @include mq($q20) {
          width: 225px;
        }

        @include mq($q16) {
          width: 256px;
        }

        @include mq($q834) {
          width: 328px;
        }

        @include mq($q428) {
          width: 163px;
          height: 160px;
        }

        @include mq($q1) {
          width: 140px;
          height: 160px;
        }

        .rtl-class & {
          @include mq($q2100) {
            width: 195px;
          }

          @include mq($q20) {
            width: 225px;
          }

          @include mq($q16) {
            width: 256px;
          }

          @include mq($q834) {
            width: 328px;
          }

          @include mq($q428) {
            width: 163px;
            height: 136px;
          }

          @include mq($q1) {
            width: 140px;
            height: 136px;
          }
        }

        .imgCatWrap {
          @include mq($q428) {
            width: 50px;
            height: 60px;
          }
        }

        &.checked {
          border: 1px solid $primary;
          background-color: $primary-5;
        }

        .p-category {
          margin-top: $spacer-3;
          min-height: 49px;

          @include mq($q428) {
            margin-top: initial;
          }

          p {
            min-height: 49px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: pre-wrap;
          }
        }

        &:hover {
          cursor: pointer;

          p {
            color: $primary !important;
          }

          border: 1px solid $primary;

          .unchecked {
            &::before {
              content: "";
              background: url("./images/hover_check.svg") no-repeat;
            }
          }
        }
      }
    }

    .category {
      border: 1px solid #D9DEE0;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0.8rem;
      width: 170px;
      text-align: center;
      margin-bottom: $spacer-5;

      @include mq($q20) {
        width: 170px;
      }

      @include mq($q1800) {
        width: 140px;
      }

      @include mq($q17) {
        width: 143px;
      }

      @include mq($q16) {
        width: 175px;
      }

      @include mq($q800) {
        width: 157px;
      }

      @include mq($q428) {
        width: 150px;
      }

      @include mq($q390) {
        width: 150px;
      }

      @include mq($q2) {
        width: 150px;
      }

      @include mq($q1) {
        width: 143px;
      }

      .rtl-class & {
        @include mq($q2100) {
          width: 195px;
        }

        @include mq($q20) {
          width: 170px;
        }

        @include mq($q1800) {
          width: 140px;
        }

        @include mq($q17) {
          width: 143px;
        }

        @include mq($q16) {
          width: 175px;
        }

        @include mq($q10) {
          width: 130px;
        }

        @include mq($q428) {
          width: 150px;
        }

        @include mq($q2) {
          width: 150px;
        }

        @include mq($q1) {
          width: 143px;
        }
      }

      &.checked {
        border: 1px solid $primary;
        background-color: $primary-5;
      }

      .p-category {
        margin-top: $spacer-3;
        min-height: 49px;

        p {
          min-height: 49px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: pre-wrap;
        }
      }

      &:hover {
        cursor: pointer;

        p {
          color: $primary !important;
        }

        border: 1px solid $primary;

        .unchecked {
          &::before {
            content: "";
            background: url("./images/hover_check.svg") no-repeat;
          }
        }
      }
    }
  }
}
