@import "../assets/mixins";
@import "../assets/variables";

.Account {
  text-align: initial;

  .notif {
    max-width: 100%;

    @include mq($q3) {
      margin-left: 37px;
    }

    @include mq($q2) {
      margin-left: 30px;
    }

    @include mq($q1) {
      margin-left: 7px;
    }
  }

  .wrapBtn {
    @include mq($q1) {
      display: flex;
      flex-direction: column;
      margin: 5px;
    }
  }

  .AutocompleteProfile {
    position: relative;

    .geoEN {
      position: absolute;
      right: 29px;
      left: unset;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }

    .geo {
      position: absolute;
      left: 29px;
      right: unset;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  &.hide-button {
    button {
      display: none;
    }
  }

  //.avatar_block {
  //  background: $primary-5;
  //  border-radius: 5px;
  //  padding: 16px;
  //
  //  .no-img {
  //    width: 90px;
  //    height: 90px;
  //    border-radius: 3px;
  //    background-color: white;
  //    border: 1px solid lightgrey;
  //    padding: 1.5rem;
  //  }
  //
  //  .existed-img {
  //    width: 90px;
  //    height: 90px;
  //    border-radius: 3px;
  //  }
  //}
}
