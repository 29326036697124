@import "../../../assets/variables";
@import "../../../assets/mixins";

.limitedInfoContainer {
  margin-top: 24px;
  display: flex;
  background-color: #FFF7F2;
  width: 100%;
  justify-content: space-around;
  padding: 24px 0;

  @media (max-width: 1279px) {
    margin-top: 32px;
  }

  @include mq($q428) {
    background-color: #fff;
    flex-direction: column;
    padding: 0;
  }
}

.limited {
  display: flex;

  @include mq($q428) {
    width: 100%;
    padding: 24px;
    background-color: #FFF7F2;
    margin-bottom: 16px;
    border-radius: 5px;
  }
}

.time {
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  @include mq($q428) {
    justify-content: space-between;
    width: 100%;
    flex-direction: unset;
  }
}

.realize {
  color: $primary !important;
  font-weight: 700;
}

.realizedAbout,
.realize {
  font-size: 16px;

  @include mq($q428) {
    font-size: 14px;
  }
}

.rtl {
  .time {
    margin-left: 0;
    margin-right: 16px;
    text-align: right;
  }
}