@import "../../../assets/variables";
@import "../../../assets/mixins";

.priceInfoContainer {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 21px;

  @include mq($q10) {
    display: flex;
  }

  @include mq($q8) {
    display: flex;
  }
}

.currency {
  font-size: 24px;
}

.price {
  font-size: 40px;

  @include mq($q428) {
    font-size: 32px;
  }
}

.discount {
  font-size: 30px;
  margin-left: 10px;
  text-transform: uppercase;

  @media (max-width: 1279px) {
    font-size: 28px;
  }

  @include mq($q428) {
    font-size: 24px;
  }
}

.priceContainer {
  font-weight: 500;
}

.actions {
  display: flex;
  height: fit-content;
  align-items: center;

  .whiteMode {
    background-color: #fff;
    color: $primary;
    border: 1px solid $primary;
  }

  &.is-ru {
    @include mq($q3) {
      flex-direction: column;
    }
  }
}

.action-button {
  width: 100%;
}

.actionsText {
  margin: 0 16px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
}

.is-ru {
  .action-button {
    @include mq($q6) {
      font-size: 12px;
    }
  }
}

.rtl {
  .discount {
    margin-left: 0;
    margin-right: 10px;
  }
    text-align: right;
}
