.categories-mobile {
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 100;

  &__header {
    padding: 16px;
    border-bottom: 1px solid #d9dee0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: -5px;
      font-weight: 500;
      color: var(--primary);
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 17px;

      .rtl-class & {
        flex-direction: row-reverse;
      }
    }
  }

  #drawer &__content {
    padding: 16px;
    padding-left: 20px;

    a {
      color: var(--dark) !important;
    }
  }

  &__parent {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 25px;
  }

  &__list {
    display: flex !important;
    flex-direction: column;
    gap: 25px;
  }

  &__item {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    background: none;
    padding: 0;
    border: none;
    font-family: inherit;
    color: var(--dark) !important;

    span {
      color: var(--dark) !important;
    }

    img {
      .rtl-class & {
        transform: rotate(180deg);
      }
    }
  }
}
