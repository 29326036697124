.modal_age {
  @media (max-width: ($grid-breakpoint-lg)-1) {
    height: auto !important;
    overflow-y: auto;
    display: flex !important;
    justify-content: center;
  }

  .modal-body {
    border-radius: 8px;
    padding: 24px !important;

    button,
    a {
      margin-top: 10px;
    }

    @media (max-width: ($grid-breakpoint-lg)-1) {
      padding: 24px 0 !important;
    }

    .img-age {
      width: 104px;
      height: 104px;
    }
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }

  60% {
    transform: scale(1.2);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}
