.languageSelect {
  justify-content: center;
  align-items: center;
}

.drawerHeader {
  padding: 24px;
}

.headerWrapper {
  display: flex;
  // justify-content: space-between;

  @media (max-width: 1278px) {
    margin: 0 -25px;
  }
}

.headerLeft {
  display: flex;
  flex: 1;

  // @media (max-width: 1278px) {
  //   flex: 1;
  // }
}

.imageUser {
  border-radius: 50% !important;
}

.drawerContainer {
  @media (max-width: 350px) {
    width: 100% !important;
    padding: 0 !important;
  }
}

.drawerContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #ffd5bf;
  width: 7px;
}

.drawerContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #fe5900;
}

.helpCenter {
  position: relative;
  display: inline-block;
  height: 85px;
  padding-top: 30px;
}

.helpBlock {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  top: 86px;
  left: -57px !important;
  padding: 21px 17px;

  .activeHelpText {
    background-color: #ffffff !important;
    display: flex !important;
    border-radius: 10px 10px 0 0;
    padding: 0px !important;
  }

  .activeContactText {
    padding-top: 17px !important;
    border-radius: 0px 0px 10px 10px;
  }

  .activeHelpText:hover {
    background-color: #ffffff !important;
  }

  &::before {
    content: "";
    top: -11px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid white;
    left: 80%;
  }
}

.helpBlockRtl {
  left: -15px;

  &::before {
    content: "";
    top: -11px;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid white;
    right: 80%;
  }
}

.helpText {
  color: black !important;
  margin-left: 17px;
  width: max-content;
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.helpTextRtl {
  margin-left: 0px;
  margin-right: 17px;
}

.helpCenterText {
  color: white !important;
  cursor: pointer;
  font-size: 14px;
  margin-right: 6px;
}

.helpCenterTextRtl {
  color: white !important;
  margin-left: 6px;
  margin-right: 0px;
}

.helpCenter:hover {
  .helpBlock {
    display: block;
  }
}

.helpCenterBlock {
  display: flex;
  align-items: center;
}
