.coupon-distance {
  // position: absolute;
  // top: 10px;
  // right: 8px;
  font-size: 15px;
  color: var(--primary);
  font-weight: 400;
  font-size: 14px;
  background: #fff;
  // padding: 3px 5px;
  border-radius: 5px;
  letter-spacing: 0.2px;
  // border: 1px solid #fe5900a3;

  .online {
    text-transform: uppercase;
    font-size: 14px;
    // background: #fff;
    // padding: 5px 7px;
    // border-radius: 5px;
  }

  .rtl-class & {
    left: 8px;
    right: unset;
  }
}
