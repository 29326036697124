@import "../../assets/variables";

.select {
  :global {
    .select__control:hover {
      border-color: hsl(0, 0%, 80%);
      box-shadow: none;
    }

    .select__control {
      height: 42px;
      border-color: hsl(0, 0%, 80%);
      box-shadow: none;
    }

    .select__indicator {
      svg {
        fill: #FE5900;
      }
    }

    .value-container {
      padding-right: 5px;
    }

    .select__input {
      caret-color: transparent;
      width: 0px !important;
    }

    .select__menu {
      top: 70px;
    }

    .select__single-value {
      padding-right: 5px;
      margin-left: 5px;
    }

    .select__menu-list {
      max-height: 220px;
    }
  }
}

.rtl {
  :global {
    .select__menu {
      text-align: right;
    }
  }
}