.categories-wrapper {
  border-bottom: 1px solid #e8e8e8;
  // padding: 10px 0px;
  background: #fff;
  position: relative;

  @media (max-width: 1279px) {
    display: none;
  }
}

.categories-desktop {
  display: flex;
  gap: 30px;

  &__item {
    cursor: pointer;
  }

  &__trigger {
    border: none;
    background: transparent;
    font-family: inherit;
    padding: 0;
    height: 40px;
    white-space: nowrap;
    font-size: 15px;

    &:hover {
      color: var(--primary);
    }
  }

  // &__contnet {
  //   position: absolute;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   animation-duration: 250ms;
  //   animation-timing-function: ease;
  //   width: 100%;
  //   // background: #fff;
  //   box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  //   padding: 20px 0px;
  // }
}

.NavigationMenuViewport {
  position: relative;
  transform-origin: top center;
  width: 100%;
  // background-color: white;
  border-radius: 6px;
  overflow: hidden;
  // box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  height: var(--radix-navigation-menu-viewport-height);
  transition: width, height, 300ms ease;
}

.ViewportPosition {
  margin-top: 1px;
  background: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100%;
  left: 0;
  perspective: 500px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

.categories-desktop__content {
  // background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  // border-bottom: 1px solid black;
  padding: 20px 0px;
}
.categories-desktop__content[data-motion="from-start"] {
  animation-name: enterFromLeft;
}
.categories-desktop__content[data-motion="from-end"] {
  animation-name: enterFromRight;
}
.categories-desktop__content[data-motion="to-start"] {
  animation-name: exitToLeft;
}
.categories-desktop__content[data-motion="to-end"] {
  animation-name: exitToRight;
}

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
}

@keyframes exitToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
