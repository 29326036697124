@import "../../assets/variables";
@import "../../assets/mixins";

.locationContainer {
  position: absolute;
  right: 16px;
  top: 16px;
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.88);
  backdrop-filter: blur(24px);
  border-radius: 30px;
}

.mobile {
  .locationContainer {
    background: #fff;
  }
  &.rtl {
    right: unset;
    left: 16px;
  }
}

.name {
  margin-left: 6px;
  @include mq($q10) {
    font-size: 14px;
  }
}

.rtl {
  margin-left: 0;
  margin-right: 6px;
  right: unset;
  left: 16px;
}