@import "../../assets/variables";
@import "../../assets/mixins";

.mainContainer {
  width: 350px;
  background-color: $base-white;
  position: relative;
  border-radius: 5px;
  border: 1px solid #F2F4F5;
  transition: .5s;
  cursor: pointer;

  &.categoryCoupon {

    @include mq($q8) {
      width: 100%;
    }
  }

  &:hover {
    transform: translate(0, -8px);
    box-shadow: 0 4px 18px rgba(105, 44, 0, 0.08);

    .quickReview {
      opacity: 1;
    }
  }

  @include mq($q10) {
    width: 332px;
  }

  @include mq($q3) {
    width: 316px;
  }

  &.specialDetails,
  &.nearBy {
    &:hover {
      transform: none;
    }

    box-shadow: 0px 12px 40px rgba(254, 89, 0, 0.08);
    width: calc(50% - 16px);

    @include mq($q10) {
      width: calc(50% - 12px);
    }

    @include mq($q9) {
      width: 100%;
      margin-bottom: 18px;
    }

    .image {
      width: 400px;
      height: 400px;
      object-fit: contain;

      @include mq($q14) {
        width: 180px;
        height: 180px;
      }
    }

    .description {
      font-size: 24px;

      @include mq($q12) {
        font-size: 16px;
      }
    }

    .priceContainer {
      @include mq($q16) {
        justify-content: flex-end;
      }
    }

    .price {
      font-size: 40px;
      text-align: end;
    }

    .discount {
      text-align: end;
    }

    .infoContainer {
      padding: 24px;

      @include mq($q16) {
        min-height: 110px;
      }

      @include mq($q10) {
        min-height: 145px;
      }
    }

    .realizedContainer {
      display: flex;
      align-items: center;

      button {
        margin-right: 16px;
        padding: 8px 16px;
        width: auto;
      }
    }
  }
}

.image {
  width: 224px;
  height: 180px;
  object-fit: contain;
}

.mobile,
.nearBy {
  &.specialDetails {
    @include mq($q9) {
      width: 100%;
    }

    .image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      object-fit: cover;
      width: 100%;
      height: 280px;

      @include mq($q10) {
        height: 208px;
      }
    }

    .description {
      @include mq($q12) {
        font-size: 16px;
      }
    }

    .infoContainer {
      @include mq($q16) {
        min-height: 190px;
      }

      @include mq($q10) {
        min-height: 156px;
      }
    }

    .priceContainer {
      @include mq($q16) {
        justify-content: flex-end;
      }
    }
  }

  .imageContainer {
    display: block;
    justify-content: center;
    margin-top: 0;
  }

  .image {
    object-fit: cover;
    width: 100%;
    height: 230px;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.actionsContainer {
  position: relative;
  width: 100%;
  border-top: 1px solid #F2F4F5;
}

.infoContainer {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.description {
  font-weight: bold;

  &.sliced {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.rtlName {
    text-align: right;
  }
}


.priceContainer {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.currency {
  font-size: 18px;
  margin-left: 4px;
  font-weight: 400;
}

.quickReview {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #F2F4F5;
  box-sizing: border-box;
  border-radius: 30px;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 24px;
  padding: 0.5rem;
  bottom: 30px;
  margin-top: 40px;
  transition: 0.3s;
  opacity: 0;
  cursor: pointer;

  @include mq($q10) {
    display: none;
  }
}

.quickReviewTooltip {
  color: #fff !important;
}

.tooltip {
  border-radius: 8px;


  p {
    font-size: 16px !important;
    line-height: 24px !important;
    color: #FFF !important;
  }
}

.rtl {
  &.specialDetails {
    .realizedContainer {
      button {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  .quickReview {
    right: unset;
    left: 24px;
  }

  .priceContainer {
    margin-right: 10px;
    margin-left: 0;
  }

  .descriptionContainer {
    align-items: flex-start;
  }

  .description {
    text-align: start;
  }

  .currency {
    margin-left: 0;
    margin-right: 4px;
  }
}