@import '../../assets/variables';

.modalContent {
  transform: translateY(-100px);
  transition: transform .3s;
}

.modalContainer {
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0;
  transition-delay: .3s;
  background-color: rgba($dark, 0.8);

  &.open {
    transition: opacity .3s;
    opacity: 1;
    z-index: 1000000;
    .modalContent{
      transform: translateY(0);
      transition-delay: .3s;
      transition: transform .3s;
    }
  }
}

