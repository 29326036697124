@import "../../assets/mixins";
@import "../../assets/variables";

.stripeContainer {
  position: absolute;
  left: 0;
  background-size: cover;
  color: white;
  width: 100%;
  top: 0;
}

.stripe {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 8px;
  position: absolute;
  font-size: 16px;
  left: -16px;
  top: 16px;
  background: #23AE15;
  border-radius: 3px 0 0;
  text-align: initial;

  &::after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    right: -15px;
    border-top: 16px solid $secondary;
    border-bottom: 16px solid $secondary;
    border-right: 16px solid transparent;
    top: 0;
  }

  &::before {
    height: 0;
    content: "";
    position: absolute;
    width: 0;
    bottom: -7.5px;
    left: 0.1px;
    border-top: 8px solid #0B7800;
    border-left: 16px solid transparent;
  }
  @include mq($q10) {
    font-size: 14px;
  }
}

.mobile {
  .stripe {
    height: unset;
    left: 16px;
    border-radius: 30px;
    padding: 4px 10px;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }
  &.rtl {
    .stripe {
      left: unset;
      right: 16px;
      @include mq($q10) {
        right: 10px;
      }
    }
  }
}

.rtl {
  .stripe {
    left: unset;
    right: -16px;
    padding-left: 6px;
    padding-right: 5px;
    width: auto;
    &::before {
      right: 0.1px;
      border-right: 16px solid transparent;
      border-left: 0;
    }
    &::after {
      right: unset;
      left: -15px;
      border-left: 16px solid transparent;
    }
  }
}