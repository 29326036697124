.input {
  padding: 16px 55px 16px 24px !important;
  position: relative !important;
  color: #000 !important;
}

.inputIcon {
  position: absolute;
  right: 24px;
  top: 16px;

  // @media (max-width: 500px) {
  //   display: none;
  // }
}

.rtl {
  .inputIcon {
    left: unset !important;
    right: 16px !important;
  }

  .input {
    padding: 16px 35px !important;
  }
}
