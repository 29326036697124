.deleteBtn {
  height: fit-content;
  border: none !important;
  padding: 0 !important;
  background: transparent !important;

  img {
    padding: 0 !important;
  }
}

.statusContainer {
  display: flex;
  align-items: center;
}

.titleNotification {
  text-align: right;
}

.descriptionNotification {
  text-align: right;
}
