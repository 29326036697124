@import "../assets/mixins";
@import "../assets/variables";

.slider-footer-parent {
  width: 100%;
  overflow-x: hidden;

  @media (max-width: $grid-breakpoint-lg) {
    //margin: 0% -6%;
  }

  .slider-footer {
    @media (min-width: $grid-breakpoint-lg) {
      &::after {
        display: table;
        content: '';
        position: absolute;
        height: 348px;
        width: 14%;
        background: #f3ffff;
        top: 0;
        z-index: 1000;
        background: linear-gradient(90deg, #FFF7F2 0%, rgba(255, 247, 242, 0.84) 29.62%, rgba(255, 247, 242, 0) 100%);
      }

      &::before {
        display: table;
        content: '';
        position: absolute;
        height: 348px;
        width: 14%;
        background: #f3ffff;
        top: 0;
        right: 0;
        z-index: 1000;
        background: linear-gradient(270deg, #FFF7F2 0%, rgba(255, 247, 242, 0.84) 29.62%, rgba(255, 247, 242, 0) 100%);
      }
    }

    .slick-slide {
      height: 340px;
      width: 384px !important;
      cursor: pointer !important;
      padding: 0 $spacer-3;
      box-sizing: border-box;

      @include mq($q2) {
        width: 365px !important;
      }

      @include mq($q1) {
        width: 320px !important;
      }

      .couponCard {
        margin-top: 0 !important;

        @include mq($q834) {
          width: 332px !important;
        }

        @include mq($q428) {
          width: 316px !important;
        }

        @include mq($q2) {
          width: 290px !important;
        }

        @include mq($q1) {
          width: 280px !important;
        }
      }
    }

    .slick-list {
      margin-bottom: $spacer-3;
      padding-top: 8px !important;
      padding: 0 10px !important;

      @include mq($q428) {
        padding: 0 !important;
      }

      @include mq($q390) {
        padding: 0 10px !important;
      }

      @include mq($q3) {
        padding: 0 17px !important;
      }

      @include mq($q2) {
        padding: 0 13px !important;
      }

      @include mq($q1) {
        padding: 0 27px !important;
      }

      .rtl-class & {
        padding: 0 55px !important;

        @include mq($q428) {
          padding: 0 80px !important;
        }

        @include mq($q4) {
          padding: 0 66px !important;
        }

        @include mq($q393) {
          padding: 0 53px !important;
        }

        @include mq($q390) {
          padding: 0 53px !important;
        }

        @include mq($q3) {
          padding: 0 45px !important;
        }

        @include mq($q2) {
          padding: 0 50px !important;
        }
      }

      // height: 420px;
      .slick-track {
        display: flex !important;
      }

      @include mq($q1) {
        height: 325px;
      }
    }

    .slick-prev {
      display: block;
      background: $primary !important;
      border-radius: 43px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      left: 24px !important;
      z-index: 1001 !important;
      width: 48px !important;
      height: 48px !important;
      margin-top: -26px;

      &::before {
        background-image: url('./images/chevron-right.svg');
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        font-size: 6px;
        position: absolute;
        left: 12px;
        top: 11px;
        content: "";
      }

      @include mq($q12) {
        display: none !important;
      }
    }

    .slick-next {
      display: block;
      background: $primary !important;
      border-radius: 43px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      right: 24px !important;
      z-index: 1001 !important;
      width: 48px !important;
      height: 48px !important;
      margin-top: -26px;
      left: unset !important;

      @include mq($q12) {
        display: none !important;
      }

      &::before {
        background-image: url('./images/chevron-left.svg');
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        font-size: 6px;
        position: absolute;
        right: 12px;
        top: 11px;
        content: "";
      }
    }

    .slick-dots {
      bottom: unset;
    }

    .slick-dots li {
      margin: 0;
    }

    .slick-dots li button::before {
      position: absolute;
      content: '\2022';
      text-align: center;
      font-size: 0.5rem;
      opacity: 1;
      color: $primary-25;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-dots li.slick-active button::before {
      color: #f95901 !important;
      opacity: 1 !important;
    }

    .slick-vertical .slick-slide:hover {
      opacity: 0.6 !important;
      cursor: pointer !important;
    }
  }
}

.slide-block-banner {
  .slick-track {
    height: fit-content;

    .slick-slide {
      height: fit-content;
    }
  }

  .slick-dots {
    bottom: 1px;

    li {
      margin: 0 -3px !important;
    }

    li button::before {
      color: $primary;
    }
  }
}
