.category-content {
  // background: #fff;
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: 20px;
    a {
      color: black;
    }
  }

  &__image {
    width: 320px;
    height: 280px;
    object-fit: cover;
  }

  &__menu {
    margin-top: 20px;
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 100px;
    }

    li {
      min-width: 200px;
      font-weight: 500;
      cursor: pointer;

      a {
        color: var(--dark);
        font-weight: 400;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }
}
