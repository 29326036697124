@import "../../assets/variables";
@import "../../assets/mixins";

.button {
  border: none;
  outline: none;

  padding: 8px 16px;
  border-radius: 5px;
  color: #fff;
  background: $primary;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  font-family: 'Arimo';

  &:disabled {
    background-color: $primary-25;
    border-color: $primary-25;
    box-shadow: none;
  }

  &.secondary {
    background-color: #fff;
    border: 1px solid $primary;
    color: $primary;
  }

  &.primary {
    background-color: $primary-5;
    border: none;
    border-radius: 30px;
    color: $primary;
    font-size: 16px;
    line-height: 23px;
    font-family: 'Heebo';
    font-weight: 500;
    padding: 9px 16px;
    text-transform: initial;
    align-items: center;
  }

  &.outline-primary {
    background-color: #fff;
    border: 1px solid #fff;
    color: $primary;
    box-shadow: 0 2px 16px 2px rgba(254, 89, 0, .05);
  }

  &.outline-primary:active {
    background-color: $primary-25;
    border: 1px solid $primary;
  }

  // @include mq($q428) {
    // Removed by MB as adjustment to stay in line with design which specified 15px font size for Mobile devices
    // font-size: 12px;
  // }
}

.rtl {
  direction: rtl;
}
