@import "../../assets/mixins";
@import "../../assets/variables";

.text {
  color: $primary !important;

  &.specialDetails {
    @include mq($q10) {
      max-width: 110px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}